import {FC} from "react";
import {Col, InputNumber, Row, SpanSubtitle1, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";

const SwitchCol = styled(Col)`
  > div {
    margin: 0
  }
`

interface CurrencyInputNumberProps {
    label: string;
    inputValue?: number | null;
    onChange: (v: number | null) => void;
    currency: string;
    active?: boolean | null;
    onActiveChange?: (v: boolean) => void;
    readOnly: boolean
}

export const CurrencyInputNumber: FC<CurrencyInputNumberProps> = ({
                                                                      label,
                                                                      inputValue,
                                                                      onChange,
                                                                      currency,
                                                                      onActiveChange,
                                                                      active,
                                                                      readOnly
                                                                  }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    return <div style={{margin: '16px 0'}}>
        <Row alignItems={'center'} gapColumn={10}>
            {(active !== undefined && active !== null) && <SwitchCol>
                <Switch
                    value={active}
                    disabled={readOnly}
                    onChange={onActiveChange}/>
            </SwitchCol>}
            <Col>
                <label style={{opacity: readOnly ? 0.5 : 1}}>
                    <SpanSubtitle1>{label}</SpanSubtitle1>
                </label>
            </Col>
        </Row>
        <Row alignItems={'center'} gapColumn={10}>
            <Col>
                <InputNumber
                    style={{margin: '-16px 0'}}
                    value={inputValue}
                    disabled={readOnly}
                    onChange={onChange}
                    min={0}/>
            </Col>
            <Col>
                <SpanSubtitle1 style={{color: theme.colors.primary, opacity: readOnly ? 0.5 : 1}}>
                    {intl.formatNumber(0, {
                        style: 'currency',
                        currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).replace(/\d/g, '').trim()}
                </SpanSubtitle1>
            </Col>
        </Row>
    </div>
}