import {FC, RefObject, useEffect, useRef, useState} from "react";
import styled, {useTheme} from "styled-components";
import {
    Button,
    Card,
    Col,
    getTypo,
    Row,
    SpanSubtitle1,
    SquareButton,
    Switch,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {ReactComponent as InfoIcon} from "../../../icons/info-outline.svg";
import {Tag} from "../../../commons/components/tag";
import {Tooltip} from "../../../commons/components/tooltip";
import {SubscriptionPlan} from "../../payment-types";

const H3 = styled.h3`
  color: ${({theme}) => theme.colors.dark};
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0 0 4px 0;
`
const Subtitle = styled.p`
  ${getTypo('body1')};
  font-weight: 700;
  margin-top: 16px;
`
const Caption = styled.p`
  ${getTypo('body1')};
  font-weight: 700;
  color: ${({theme}) => theme.colors.dark};
  font-size: 14px;
  margin-top: 16px;
`
const SwitchLabel = styled.label`
  ${getTypo('input')};
  font-weight: 600;
  color: ${({theme}) => theme.colors.primary};
  font-size: 15px;
  line-height: 18px;
`
const ButtonWrapper = styled(Row)`
  gap: 24px;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 767px) {
    flex-direction: row;
    align-items: center;
    padding: 16px;
  }
`
const ServicesList = styled.div<{ show: boolean }>`
  ${({show}) => !show && `display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden`};
`
const ButtonLink = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: inherit;
  font-family: 'Raleway', sans-serif;
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${({theme}) => theme.colors.primary};
`

interface SubscriptionCardProps {
    name: string;
    number: number;
    services: (string | undefined)[];
    currency: string;
    plans: SubscriptionPlan[];
    display: boolean;
    setDisplay: (val: boolean) => void;
    onDeleteSubscription: () => void;
    onEditClick: () => void;
    onDuplicateClick: () => void;
    onCancelSubscription: () => void;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
                                                                name,
                                                                number,
                                                                services,
                                                                currency,
                                                                plans,
                                                                display,
                                                                setDisplay,
                                                                onDeleteSubscription,
                                                                onEditClick,
                                                                onDuplicateClick,
                                                                onCancelSubscription
                                                            }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const contentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(null);
    const servicesRef = useRef<HTMLParagraphElement | null>(null);
    const [isOverFlowing, setIsOverFlowing] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        if (!servicesRef.current) {
            return
        }
        if (servicesRef.current?.offsetHeight < servicesRef.current?.scrollHeight) {
            setIsOverFlowing(true);
        }
    }, []);

    const formatPlanText = (plan: SubscriptionPlan) => {
        const price = intl.formatNumber(plan.price || 0, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
        let period;
        switch (plan.numberOfMonths) {
            case 1 :
                period = intl.formatMessage({id: 'monthly', defaultMessage: 'mensuel'})
                break;
            case 3 :
                period = intl.formatMessage({id: 'quarterly', defaultMessage: 'trimestriel'})
                break;
            case 12 :
                period = intl.formatMessage({id: 'annual', defaultMessage: 'annuel'})
                break;
            default:
                period = ''
        }
        return `${price} ${period}`
    }

    return <div style={{overflow: 'hidden'}} ref={contentRef}>
        <Card style={{marginBottom: '16px'}}>
            <Row>
                <Col columns={[12, 12, 4.75]}>
                    <H3>{name}</H3>
                    {plans.map(plan => <Subtitle key={plan.id}>{formatPlanText(plan)}</Subtitle>)}
                    <Caption>
                        <FormattedMessage
                            id={"paramasSubscriptionCardCaptionSubscribers"}
                            defaultMessage={"{number} Abonné(s)"}
                            values={{number}}/>
                    </Caption>
                    <Row wraps={'nowrap'} style={{width: '100%'}} alignItems={'center'}>
                        <Col>
                            <Switch value={display} onChange={setDisplay}/>
                        </Col>
                        <Col style={{padding: 0}}>
                            <SwitchLabel>{intl.formatMessage({
                                id: 'paramsSubscriptionCardSwitchLabel',
                                defaultMessage: 'Afficher l’offre d’abonnement'
                            })}</SwitchLabel>
                        </Col>
                        <Col style={{padding: 10}}>
                            <Tooltip message={intl.formatMessage({
                                id: 'paramsSubscriptionCardToolTipText',
                                defaultMessage: 'Lorsque vous désactivez l’affichage, l’offre ne sera plus visible par vos clients, mais vous ne perdez pas vos abonnés.'
                            })}>
                                <InfoIcon height={20} width={20}/>
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col columns={[12, 12, 4.5]}>
                    <ServicesList ref={servicesRef} show={show}>
                        <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                            <FormattedMessage
                                id={'paramsSubscriptionCardServicesListTitle'}
                                defaultMessage={'Prestations accessibles : '}
                            />
                        </SpanSubtitle1>
                        {services.map((el, index) => {
                            if (el === undefined) {
                                return null
                            } else {
                                return <Tag key={index} small>
                                    {el}
                                </Tag>
                            }
                        })}
                    </ServicesList>
                    {isOverFlowing && <ButtonLink onClick={() => setShow(!show)}>
                        <FormattedMessage id={'paramsSubscriptionCardOverFlowingButtonLabel'}
                                          defaultMessage={'Voir toutes les prestations'}/>
                    </ButtonLink>}
                </Col>
                <Col columns={[12, 12, 2.5]}>
                    <ButtonWrapper>
                        <SquareButton icon={'edit'} onClick={onEditClick}/>
                        <SquareButton icon={'duplicate'} onClick={onDuplicateClick}/>
                        {number > 0
                            ? <Button small colorType={'outline'}
                                      style={{padding: '8px 4px'}}
                                      icon={'stop'}
                                      onClick={onCancelSubscription}
                                      fontColor={theme.colors.danger}
                                      borderColor={theme.colors.danger}>
                                <FormattedMessage id={'paramsSubscriptionCardStopButtonlabel'}
                                                  defaultMessage={'Arrêter le renouvellement'}/>
                            </Button>
                            : <SquareButton icon={'delete'} fontColor={theme.colors.danger}
                                            onClick={onDeleteSubscription}/>}
                    </ButtonWrapper>
                </Col>
            </Row>
        </Card>
    </div>
}