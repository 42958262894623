import React, { FC, useEffect, useState } from "react";
import { PageWrapper } from "../../../commons/components/page-wrapper";
import { Container, InfiniteScroll, useToaster } from "@linkeo.com/ui-lib-react";
import { ContainerHeader } from "../../../commons/components/container-header";
import { useIntl } from "react-intl";
import { SubscriptionCard } from "../../../params/components/subscriptions/subscription-card";
import { BackToTop } from "../../../commons/components/back-to-top";
import { usePaymentStore } from "../../../params/payment-store";
import { EmptyList } from "../../../commons/components/empty-list";
import { useConfigurationStore } from "../../../params/configuration-store";
import { useServicesStore } from "../../../services/services-store";
import { routeParamsSubscriptionCreate, routeParamsSubscriptionEdit } from "../../../routes";
import { Subscription } from "../../../params/payment-types";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { SubscriptionDialog } from "../../../params/components/subscriptions/subscription-dialog";
import { FRONT_URI } from "../../../config/config";
import { useAuthStore } from "../../../providers/sso/auth-provider";

export const ParamsSubscriptionsListPage: FC = () => {
    const {locale, ...intl} = useIntl();
    const {codeBouton} = useAuthStore();
    const toast = useToaster();
    const navigate = useNavigate();
    const {
        subscriptionsList,
        fetchSubscriptionList,
        loadMoreSubscriptions,
        deleteSubscription,
        editSubscription,
        duplicateSubscription,
        cancelSubscription,
        resetSubscription
    } = usePaymentStore();
    const { fetchAllServices, servicesList } = useServicesStore();
    const { config, fetchData } = useConfigurationStore();
    const [loading, setLoading] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(false);
    const [lock, setLock] = useState<boolean>(false);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>('')
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

    useEffect(() => {
        Promise.all([fetchSubscriptionList(), fetchAllServices(), fetchData()])
            .catch(e => console.error(e))
            .finally(() => setLoading(false))
        return () => resetSubscription()
    }, [fetchAllServices, fetchData, fetchSubscriptionList, resetSubscription]);

    const onListLoading = async () => {
        setLoader(true);
        try {
            const lock = await loadMoreSubscriptions();
            setLock(lock)
        } catch (e) {
            console.error(e);
        } finally {
            setLoader(false)
        }
    }

    const onDeleteSubscriptionClick = (id: string) => {
        setSelectedSubscriptionId(id);
        setOpenDeleteModal(true)
    }

    const onCancelSubscriptionClick = (id: string) => {
        setSelectedSubscriptionId(id);
        setOpenCancelModal(true)
    }

    const onDeleteSubscription = async () => {
        setOpenDeleteModal(false);
        setLoading(true);
        try {
            await deleteSubscription(selectedSubscriptionId)
            await fetchSubscriptionList()
        } catch (e) {
            console.error(e);
            toast(intl.formatMessage({
                id: 'GiftcardCreateErrorMessage',
                defaultMessage: 'Une erreur s\'est produite, veuillez réessayer. Si le problème persiste, veuillez contacter le service client.'
            }))
        } finally {
            setLoading(false)
        }
    }

    const onCancelSubscription = async () => {
        setOpenCancelModal(false);
        setLoading(true);
        try {
            await cancelSubscription(selectedSubscriptionId);
            await fetchSubscriptionList()
        } catch (e) {
            console.error(e);
            toast(intl.formatMessage({
                id: 'GiftcardCreateErrorMessage',
                defaultMessage: 'Une erreur s\'est produite, veuillez réessayer. Si le problème persiste, veuillez contacter le service client.'
            }))
        } finally {
            setLoading(false)
        }
    }

    const onDisplaySubscriptionClick = async (display: boolean, sub: Subscription) => {
        try {
            await editSubscription({ ...sub, display },
                {
                    businessEmail: config.email||"",
                    businessName: config.name,
                    businessPhone: config.phone||"",
                    frontSubscriptionFormulasUri: `${FRONT_URI}/${codeBouton}/${locale}/subscription`,
                    frontTakeAppointmentUri: `${FRONT_URI}/${codeBouton}/${locale}/appointment`,
                }, sub.id);
            await fetchSubscriptionList()
        } catch (e) {
            console.error(e)
            toast(intl.formatMessage({ id: 'errorMessage', defaultMessage: 'Une erreur est survenue' }))
        }
    }

    const onDuplicateClick = async (id: string) => {
        try {
            await duplicateSubscription(id)
            navigate(routeParamsSubscriptionCreate)
        } catch (e) {
            console.error(e)
            toast(intl.formatMessage({ id: 'errorMessage', defaultMessage: 'Une erreur est survenue' }))
        }
    }

    return <PageWrapper isLoading={loading}>
        <Container size={'lg'}>
            {subscriptionsList.length > 0 ? <>
                <ContainerHeader
                    title={intl.formatMessage({
                        id: 'paramsSubscriptionsListPageTitle',
                        defaultMessage: 'Toutes les formules d’abonnements'
                    })}
                    subtitle={intl.formatMessage({
                        id: 'paramsSubscriptionsListPageSubTitle',
                        defaultMessage: 'Ajoutez les différents abonnements que vous proposez. {br}{br}Vous pouvez modifier le Nom de l’abonnement et les Prestations incluses. Pour supprimer et modifier les Plans tarifaires et les conditions de vos abonnement vous devez avoir 0 abonné actif.'
                    }, { br: <br /> }) as string}
                    buttonLabel={intl.formatMessage({
                        id: 'paramsSubscriptionsListHeaderButtonLabel',
                        defaultMessage: '+ Ajouter un abonnement'
                    })}
                    to={routeParamsSubscriptionCreate}
                />
                <SubscriptionDialog
                    onClose={() => setOpenDeleteModal(false)}
                    show={openDeleteModal}
                    onApproveClick={onDeleteSubscription}
                    text={intl.formatMessage({
                        id: 'ParamsSubscriptionListPageRemoveModalText',
                        defaultMessage: 'Etes-vous sûr(e) de vouloir supprimer cet abonnement?'
                    })}
                    title={intl.formatMessage({
                        id: 'ParamsSubscriptionListPageRemoveModalTitle',
                        defaultMessage: 'Supprimer cet abonnement'
                    })}
                />
                <SubscriptionDialog
                    onClose={() => setOpenCancelModal(false)}
                    show={openCancelModal}
                    onApproveClick={onCancelSubscription}
                    text={intl.formatMessage({
                        id: 'ParamsSubscriptionCancelModalText',
                        defaultMessage: 'Etes-vous sûr(e) de vouloir arrêter le renouvellement de cet abonnement? {br}Un email sera envoyé à vos abonnés pour les informer du non-renouvellement de leur formule d\'abonnement'
                    }, {br: <br />}) as string}
                    title={intl.formatMessage({
                        id: 'paramsSubscriptionCardStopButtonlabel',
                        defaultMessage: 'Arrêter le renouvellement'
                    })} />
                <InfiniteScroll isLoading={loader}
                    lock={lock}
                    moreResultLabel={intl.formatMessage({
                        id: 'paramsSubscriptionsListPageLoadMoreLabel',
                        defaultMessage: 'Charger plus d\'abonnements'
                    })}
                    onNext={onListLoading}>
                    {subscriptionsList.map(sub =>
                        <SubscriptionCard key={sub.id}
                            currency={config.currency}
                            display={sub.display}
                            setDisplay={(display) => onDisplaySubscriptionClick(display, sub)}
                            plans={sub.plans.filter(plan => plan.active)}
                            name={sub.name}
                            number={sub.subscriptions.length}
                            services={sub.services.map(s => servicesList.find(el => el.id === s.id)?.title)}
                            onDeleteSubscription={() => onDeleteSubscriptionClick(sub.id)}
                            onEditClick={() => navigate(generatePath(routeParamsSubscriptionEdit, { id: sub.id }))}
                            onDuplicateClick={() => onDuplicateClick(sub.id)}
                            onCancelSubscription={() => onCancelSubscriptionClick(sub.id)}
                        />)}
                </InfiniteScroll>
            </>
                : <EmptyList
                    buttonLabel={intl.formatMessage({
                        id: 'paramsSubscriptionsListHeaderButtonLabel',
                        defaultMessage: '+ Ajouter un abonnement'
                    })}
                    to={routeParamsSubscriptionCreate}
                    icon={'list'}
                    title={intl.formatMessage({
                        id: 'paramsSubscriptionsListEmptyListTitle',
                        defaultMessage: 'Liste des abonnements'
                    })}
                    subtitle={intl.formatMessage({
                        id: 'paramsSubscriptionsListEmptyListSubTitle',
                        defaultMessage: "Ajoutez les différents abonnements que vous proposez."
                    })} />}
            <BackToTop />
        </Container>
    </PageWrapper>
}